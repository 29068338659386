// @ts-nocheck

import { unmimicAccount } from 'actions/adminActions';
import { setInitialAmountType, setPlans } from 'actions/optionsActions';
import { setIncludeProcessingFee, setSuccessInfo } from 'actions/paymentActions';
import { getPreferences } from 'actions/preferencesActions';
import { createBrowserHistory } from 'history';
import _ from 'lodash';
import { crmDict } from 'modules/constants';
import { getCookie } from 'modules/cookie';
import { sendGtmPixel } from 'modules/gtm';
import { campaignIgnoreSites } from 'modules/siteContents';
import { constructRedirectUri, getUtmsFromCookie } from 'modules/utils';
import queryString from 'query-string';
import React, { Component, Suspense } from 'react';
import { Modal } from 'react-bootstrap';
import { connect, ConnectedProps } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import Footer from 'sharedComponents/Footer';
import Header from 'sharedComponents/Header';
import MimicState from 'sharedComponents/MimicState';
import ReceiptBanner from 'sharedComponents/ReceiptBanner';
import Spinner from 'sharedComponents/Spinner';
import SystemMessage from 'sharedComponents/SystemMessage';
import { getAccessToken } from 'slices/accessSlice';
// import { initAccountPreferences } from 'slices/accountSlice';
import { getRecaptchaToken, loadRecaptcha, setRecaptchaV2Token } from 'slices/recaptchaSlice';
import {
    getCampaigns, getStatusReasons, loadAppState, retrieveCustomer, retrieveRoles, saveAppState,
    setCrm, setProfileAuth0, setRecaptchaWhitelistKey, setUtms
} from 'slices/rootSlice';
import sbjs from 'sourcebuster';
import { recaptchaToken } from "modules/token";
import { layoutConfigs } from 'modules/constants'

import Admin from './Admin';
import Donate from './Donate/Donate';
import DonateExpress from './DonateExpress';
import PaymentMethods from './PaymentMethods';
import Payments from './Payments';
import Preferences from './Preferences';
import Subscriptions from './Subscriptions';
import Success from './Success';
import Verify from './Verify';
import Attendees from './Attendees';

import type { RootState } from 'src/store'
import type { AppRouteProps } from 'src/types/app'

const customHistory = createBrowserHistory()

const mapState = ({ root, account, options, payment, access, charityRun, recaptcha, contact }: RootState) => ({
  token: access.token,
  userRoles: root.userRoles,
  isAdmin: !!root.userRoles.find((r: {name:string}) => r.name === 'Admin'),
  isAdminTerminal: !!root.userRoles.find((r: {name:string}) => r.name === 'Admin Terminal'),
  mimicState: root.mimicState,
  guestId: root.guestId,
  email: account.contact.email,
  loadingCustomer: root.loadingCustomer,
  loadingRoles: root.loadingRoles,
  receiptUrl: root.receiptUrl,
  donationAmount: options.donationAmount,
  donationType: options.donationType,
  successInfo: payment.successInfo,
  stripeTerminals: payment.stripeTerminals,
  crm: root.crm,
  utms: root.utms,
  campaigns: root.campaigns,
  recaptcha,
  charityRun, 
  campaignId: contact.campaignId,
  eventContent: _.get(root.campaigns.find(c => c.id === contact.campaignId), ['options', 'event', 'content']),
  hasEventRegistrations: root.hasEventRegistrations,
})

const actionCreators = {
  getAccessToken,
  retrieveCustomer,
  retrieveRoles,
  setUtms,
  setProfileAuth0,
  saveAppState,
  loadAppState,
  getCampaigns,
  getStatusReasons,
  getPreferences,
  // initAccountPreferences,
  setPlans,
  unmimicAccount,
  setInitialAmountType,
  setRecaptchaWhitelistKey,
  setIncludeProcessingFee,
  setSuccessInfo,
  setCrm,
  loadRecaptcha,
  getRecaptchaToken,
  setRecaptchaV2Token,
}

const connector = connect(mapState, actionCreators)
type AppProps = ConnectedProps<typeof connector> & AppRouteProps;

/**
 * Main App Router
 * 
 * Using React-Router-Dom, lazy loads with Suspense and React.Lazy. 
 * 
 */
class AppRouter extends Component<AppProps> {

  state = { customerLoaded: false } // Initial load
  /**
   * Called after the first render.
   */
  componentDidMount() {
    const loginConfig = this.checkLogin()
    if (loginConfig) {
      return this.props.loginWithRedirect!({authorizationParams: loginConfig })
    }
    const {
      isAdmin,
      setUtms,
      setCrm,
      profileAuth0,
      setProfileAuth0,
      setRecaptchaWhitelistKey,
      loadAppState,
      getAccessTokenSilently,
      getAccessToken,
      isLoginRedirect, getCampaigns,
      getStatusReasons,
      retrieveRoles,
      getPreferences,
      initAccountPreferences,
      setInitialAmountType,
      initialUrlParams,
      setPlans,
      setIncludeProcessingFee,
      setSuccessInfo,
      loginWithRedirect,
      site,
      // token,
      // charityRun,
      recaptcha,
      loadRecaptcha,
    } = this.props
    customHistory.replace(customHistory.location.pathname + '?' + queryString.stringify(_.pick(initialUrlParams, [
      'utm_campaign', 'utm_medium', 'utm_source', 'utm_content', 'utm_term', 'term', 'amount', 'cid', 'sponsorship', 'layout',
    ])))
    sbjs.init({ domain: this.props.host.cookie.replace(/^\./, '') })
    let utms = sbjs.get
    utms = {
      ...utms,
      current: {
        ...getUtmsFromCookie(),
        ...(_.get(utms, 'current')),
      }
    }
    setUtms(utms)
    if (crmDict[site]) setCrm(crmDict[site])
    setProfileAuth0(profileAuth0)

    setPlans!(
      initialUrlParams.optm || getCookie('optm'), 
      initialUrlParams.opty || getCookie('opty'), 
      initialUrlParams.opto || getCookie('opto')
    )
    if (initialUrlParams.key) setRecaptchaWhitelistKey!(initialUrlParams.key)

    setInitialAmountType({ 
      amount: initialUrlParams.amount || getCookie('amount'),
      type: initialUrlParams.term || getCookie('term'),
      onlyShowTypes: (initialUrlParams.show || '').toLowerCase().split(','),
    })
    
    loadAppState()
    if (profileAuth0) {
      if (isLoginRedirect) {
        sendGtmPixel({
          event: 'login_success',
          email: profileAuth0.email,
        })
      }
      setIncludeProcessingFee(false)

      getAccessToken()
        .then(() => retrieveRoles())
        // .then(() => getPreferences(getAccessTokenSilently))

      // initAccountPreferences()
    }
    else {
      if (location.pathname === '/payment_methods') {
        loginWithRedirect({ authorizationParams: {redirect_uri: `${location.protocol}//${location.host}/payment_methods`}})
      }
    }

    getStatusReasons(isAdmin)
    if (!recaptcha.ready) {
      loadRecaptcha()
    }

    getCampaigns(initialUrlParams.cid || (getCookie('cid') as string))

    if (initialUrlParams.success_debug) {
      setSuccessInfo({ message: `You have made a donation`})
    }
    return {}
  }


  /**
   * Called after the component is updated in the DOM.
   * 
   * @param prevProps Props before state update
   */
  componentDidUpdate(prevProps: Readonly<AppProps>, prevState: Readonly<{}>, snapshot?: any): void {
    document.body.style.removeProperty('overflow')

    const { 
      retrieveCustomer, 
      profileAuth0, 
      initialUrlParams, 
      getStatusReasons,
      recaptcha,
      isAdmin,
      loadingCustomer,
      getAccessTokenSilently,
      campaignId,
      campaigns,
      eventContent,
    } = this.props
    
    const layoutConfig = (eventContent?.layoutExclusion || []).includes(initialUrlParams.layout) ? null : (layoutConfigs[initialUrlParams.layout] || null)
    // recaptcha status changed
    if (prevProps.recaptcha.status !== 'idle' && recaptcha.status === 'idle' && 
      !loadingCustomer &&
      (!profileAuth0 || profileAuth0.email_verified)) {
      const { aid: accountId, contactid: contactId } = initialUrlParams
      if (!this.state.customerLoaded) {
        retrieveCustomer({ accountId, contactId, layoutConfig })
        this.setState({ customerLoaded: true })
      }
    } else {
      getRecaptchaToken()
    }

    // admin status changed
    if (!prevProps.isAdmin && isAdmin) {
      getStatusReasons(true)
    }

    const parsedQueries = queryString.parse(location.search)
    // if (!campaignId && campaigns.length) {
    //   const defaultCampaign = campaigns.find(c => c.options?.setAsDefault)
    //   if (defaultCampaign) {
    //     parsedQueries.cid = defaultCampaign.id
    //   }
    // }

    customHistory.replace(location.pathname + '?' + queryString.stringify(_.pick(
      parsedQueries,
      [
      'utm_campaign', 'utm_medium', 'utm_source', 'utm_content', 'utm_term', 'term', 'amount', 'cid', 'sponsorship', 'layout',
    ])))

    sessionStorage.setItem('landing_url', location.protocol + '//' + location.hostname + location.pathname + '?' + queryString.stringify(_.pick(
      initialUrlParams,
      [
      'utm_campaign', 'utm_medium', 'utm_source', 'utm_content', 'utm_term', 'term', 'amount', 'cid', 'sponsorship', 'layout',
    ])))
  }

  /**
   * Check Login
   * 
   * @returns login config or false
   */
  checkLogin() {
    const login = location.pathname.includes('/login')
    const signup = location.pathname.includes('/signup')
    const { initialUrlParams } = this.props

    if (login || signup) {
      const loginConfig = {
        redirectUri: constructRedirectUri(initialUrlParams, '/donate'),
        screen_hint: '',
        login_hint: '',
      }
      if (signup) {
        loginConfig.screen_hint = 'signup'
      }
      if (initialUrlParams.email) {
        loginConfig.login_hint = initialUrlParams.email
      }

      return loginConfig
    }
    
    return false
  }

  /**
   * @returns JSX
   */
  render() {
    const login = location.pathname.includes('/login'), signup = location.pathname.includes('/signup')

    if (login || signup || !this.props.recaptcha.ready || this.props.loadingRoles) {
      return <div className="center"><Spinner /></div>
    }
    
    const {
      isAdmin,
      isAdminTerminal,
      saveAppState,
      guestId,
      email,
      getAccessTokenSilently,
      loadingCustomer,
      // loadingRoles,
      receiptUrl,
      loginWithRedirect,
      profileAuth0,
      // isLoginRedirect,
      mimicState,
      unmimicAccount,
      site,
      donationAmount,
      donationType,
      initialUrlParams,
      initialPath,
      successInfo,
      stripeTerminals,
      crm,
      utms,
      recaptcha,
      campaignId,
      eventContent,
      hasEventRegistrations,
    } = this.props
    const layoutConfig = (eventContent?.layoutExclusion || []).includes(initialUrlParams.layout) ? null : (layoutConfigs[initialUrlParams.layout] || null)
    const routeParams = { getAccessTokenSilently, loginWithRedirect, initialUrlParams, layoutConfig, site, crm, isAdminTerminal }
    const defaultRedirect = `/donate${location.search}`
    let routerSwitch

    if (profileAuth0 && !profileAuth0.email_verified) {
      routerSwitch = (
        <Routes>
          <Route path="/verify" element={<Verify {...this.props} initialUrlParams={initialUrlParams} initialPath={initialPath} />} />
          <Route path="*" element={<Navigate to="/verify" replace />} />
        </Routes>
      )
    }
    else if (!profileAuth0) {
      routerSwitch = (
        <Routes>
          <Route path="/donate" element={
            <Donate 
              {...routeParams}
              {...this.props}
              guestMode={true}
            />
          }/>
          <Route path="/payment_methods" element={<PaymentMethods {...this.props} {...routeParams} isAdmin={isAdmin} loggedIn={false} />} />
          { successInfo && <Route path="/success" element={<Success {...this.props} {...routeParams} />} /> }
          <Route path="/admin" element={<Admin {...this.props} {...routeParams} isAdmin={isAdmin} />} />
          <Route path="*" element={<Navigate to={defaultRedirect} replace />} />
        </Routes>
      )
    }
    else if (isAdminTerminal) {
      routerSwitch = (
        <Routes>
          <Route path="/donate" element={<DonateExpress {...this.props} {...routeParams} guestMode={true}/>} />
          { successInfo && <Route path="/success" element={<Success {...this.props} {...routeParams} />} /> }
          <Route path="/" element={<Navigate to={defaultRedirect} replace />} />
        </Routes>
      )
    }
    else {
      routerSwitch = (
        <Routes>
          <Route path="/donate" element={<Donate {...this.props} {...routeParams} />} />
          <Route path="/subscriptions" element={<Subscriptions {...this.props} {...routeParams} />} />
          <Route path="/payments" element={<Payments {...this.props} {...routeParams} isAdmin={isAdmin} />} />
          <Route path="/payment_methods" element={<PaymentMethods {...this.props} {...routeParams} isAdmin={isAdmin} />} />
          { successInfo && <Route path="/success" element={<Success {...this.props} {...routeParams} />} /> }
          { crm === 'salesforce' ? <Route path="/preferences" element={<Preferences {...this.props} {...routeParams} email={email} />} /> : null }
          { hasEventRegistrations ? <Route path="/attendees" element={<Attendees {...this.props} {...routeParams} />} /> : null }
          <Route path="/admin" element={<Admin {...this.props} {...routeParams} isAdmin={isAdmin} />} />
          <Route path="*" element={<Navigate to={defaultRedirect} replace />} />
        </Routes>
      )
    }

    return (
      <>
        <Header 
          {...this.props}
          saveAppState={saveAppState} 
          guestId={guestId} 
          email={email} 
          isAdmin={isAdmin} 
          donationType={donationType}
          donationAmount={donationAmount}
          search={window.location.search}
          pathname={window.location.pathname}
          successInfo={successInfo}
          isAdminTerminal={isAdminTerminal}
          utmCampaign={_.get(utms, ['current', 'cmp'], '')}
          initialUrlParams={initialUrlParams}
          layoutConfig={layoutConfig}
        />
        <MimicState 
          state={mimicState}
          unmimicAccount={unmimicAccount} 
          getAccessTokenSilently={getAccessTokenSilently}
          isAdmin={isAdmin}
          layoutConfig={layoutConfig}
        />
        {/* { 
          receiptUrl ? (
            <ReceiptBanner
              receiptHost={this.props.host.receipts}
              receiptUrl={receiptUrl}
              layoutConfig={layoutConfig}
            />
          ) : null
        } */}
        <SystemMessage />
        {
          loadingCustomer === 'sync' ? (
            <Modal show={true} className={`modal-center modal-${site}`}>
              <Modal.Body>
                <div>
                  <span>Please wait for your account to be synchronized..</span>
                  <span className="fa fa-spinner fa-pulse float-right"/>
                </div>
              </Modal.Body>
            </Modal>
          ) : null
        }
        <Suspense fallback={<Spinner />}>
          { routerSwitch }
        </Suspense>
        <Footer 
          site={site} 
          isAdminTerminal={isAdminTerminal} 
          stripeTerminals={stripeTerminals} 
          guestMode={!profileAuth0} 
          initialUrlParams={initialUrlParams}
          layoutConfig={layoutConfig}
        />
      </>
    )
  }
}

export default connector(AppRouter)
